import {Injectable} from '@angular/core';
declare let gtag: Function;

@Injectable()
export class GoogleAnalyticsService {

  /**
   * load analytics
   * @param trackingID
   */
  static loadGoogleAnalytics(trackingID: string): void {
    const gaScript1 = document.createElement('script');
    gaScript1.innerText= ` window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());gtag(\'config\', \'${trackingID}\')`;
    document.documentElement.firstChild.appendChild(gaScript1);

  }
  
  static eventEmitter(eventName: string)
   {
    gtag('event', eventName)
  }
}
