import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { ContractService } from "./core/services/contract.service";
import { GoogleAnalyticsService } from "./core/services/google-analytics.service";
declare let gtag: Function;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(public translate: TranslateService, public router: Router) {
    GoogleAnalyticsService.loadGoogleAnalytics(environment.gaTrackingID);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag("config", environment.gaTrackingID, {
          page_path: event.urlAfterRedirects,
        });
        GoogleAnalyticsService.eventEmitter(event.urlAfterRedirects);
      }
    });
    translate.setDefaultLang("en");
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : "en");
  }

  ngOnInit() {
    // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
  }
}
