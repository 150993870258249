<div class="container-fluid">
  <app-page-title title="" [breadcrumbItems]="breadCrumbItems"></app-page-title>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">{{'Sprints' | translate}}</h4>
          <div class="hori-timeline">
            <owl-carousel-o [options]="timelineCarousel" class="events navs-carousel">
              <ng-template *ngFor="let sprint of cohort?.active_phase?.sprints;let i=index" carouselSlide>
                <div class="item event-list"
                  [ngClass]="{ 'active': sprint.sprint_id == cohort.active_phase.active_sprint_id }">
                  <div>
                    <div class="event-date">
                      <div class="text-primary mb-1">{{sprint.start_date | date:'d MMMM' }}</div>
                      <h5 class="mb-4">{{sprint.name}}</h5>
                    </div>
                    <div class="event-down-icon">
                      <i class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                    </div>

                    <div class="mt-3 px-3">
                      <p class="text-muted">{{sprint.description}}</p>
                    </div>
                  </div>
                </div>
              </ng-template>
            </owl-carousel-o>
          </div>
          <div class="text-end" *ngIf="move">
            <button class="btn btn-primary" (click)="clickToMove()"> {{'ToTheNextSprint' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
