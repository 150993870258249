import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [

    {
        id: 8,
        label: 'Admin',
        isTitle: true
    },
    {
      id: 2,
      label: 'DASHBOARDS',
      icon: 'bx-home-circle',
      link: '/admin/dashboard',
  },
    {
        id: 9,
        label: 'Cohorts',
        icon: 'bx-collection',
        link: '/',
        subItems: [
            {
                id: 3,
                label: 'CohortsList',
                link: '/admin/manage-cohorts/list-cohorts',
                parentId: 9
            },
        ]
    },
    {
        id: 10,
        label: 'Users',
        icon: 'bxs-user-detail',
        link: '/',
        subItems: [
            {
                id: 13,
                label: 'UsersList',
                link: '/admin/manage-users/users',
                parentId: 10
            },
        ]
    },
    {
        id: 11,
        label: 'Projects',
        icon: 'bx-briefcase',
        link: '',
        subItems: [
            {
                id: 12,
                label: 'ProjectList',
                link: '/admin/manage-projects/list-project',
                parentId: 11
            },
        ]
    },
    {
      id: 14,
      label: 'Roles',
      icon: 'bx bx-accessibility',
      link: '/admin/manage-roles/roles',
      subItems: [
        {
          id: 19,
          label: 'Roleslist',
          link: '/admin/manage-roles/roles',
          parentId: 14
        },
      ]
  },
  {
    id: 15,
    label: 'Mail',
    icon: 'bx-envelope',
    link: '',
    subItems: [
        {
            id: 15,
            label: 'MailList',
            link: '/admin/manage-mail/list-mail',
            parentId: 15
        },
    ]
},

{
  id: 15,
  label: 'Session',
  icon: 'bx bx-video-recording ',
  link: '',
  subItems: [
      {
          id: 15,
          label: 'Sessionlist',
          link: '/admin/manage-sessions/list-session',
          parentId: 15
      },
  ]
},

{
  id: 16,
  label: 'Announcement',
  icon: 'bx bxs-megaphone',
  link: '',
  subItems: [
      {
          id: 15,
          label: 'ListAnouncement',
          link: '/admin/manage-announcements/list-announcement',
          parentId: 16
      },
  ]
}
];

