export const constants = {
    ROLE: {
        ADMIN: { id: 1, name: 'Admin' },
        PROPOSER: { id: 2, name: 'Proposer' },
        CHEXPERT: { id: 3, name: 'Challenge Expert' },
        BEXPERT: { id: 4, name: 'Business Expert' },
        MENTOR: { id: 5, name: 'Mentor' },
        COMEMBER: { id: 6, name: 'Community Member' },
        PARTNER: { id: 7, name: 'Partner' }
    }
}