import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Announcement } from "../models/announcement";

@Injectable({
  providedIn: "root",
})
export class AnnouncementService {
  constructor(private http: HttpClient) {}

  addAnnoucement(annoucement: Announcement) {
    const url = environment.baseUrl + "/announcement/add";
    return this.http.post(url, annoucement);
  }

  getAnnouncements(    page: number = null,
    perPage:number = null) {
    const url = environment.baseUrl +  `/announcement/all??page=${page}&perPage=${perPage}`;
    return this.http.get(url);
  }

    getCurrentAnnouncements() {
    const url = environment.baseUrl + "/announcement/current";
    return this.http.get(url);
  }

  getAnnouncement(announcement_id: number) {
    const url = environment.baseUrl + "/announcement/" + announcement_id;
    return this.http.get(url);
  }


  editAnnoucement(annoucement: Announcement,announcement_id: number) {
    const url = environment.baseUrl + "/announcement/edit/"+ announcement_id;
    return this.http.put(url, annoucement);
  }

  deleteAnnoucement(announcement_id: number) {
    const url = environment.baseUrl + "/announcement/delete/"+ announcement_id;
    return this.http.delete(url);
  }
}
