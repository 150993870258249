import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";

import { getFirebaseBackend } from "../../authUtils";

import { Credentials } from "../models/credentials";
import { User } from "../models/user";
import { StorageService } from "./storage.service";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  user: User;

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {}

  /**
   * Returns the current user
   */
  public currentUser(): User {
    return getFirebaseBackend().getAuthenticatedUser();
  }

  login(credentials: Credentials, roleIds: number[]) {
    const url = environment.baseUrl + "/auth/login/user?roles=" + roleIds;
    return this.http.post<any>(url, credentials);
  }

  isLoggedIn() {
    return this.getUserToken() != null;
  }

  getUserToken() {
    return this.storageService.read(StorageService.USER_TOKEN_KEY);
  }

  checkIfAdminHasRole(roleId: number) {
    this.user = this.storageService.read(StorageService.USER_KEY);
    return this.user.role_id === roleId;
  }

  checkIfUserRole(roleId: number) {
    this.user = this.storageService.read(StorageService.USER_KEY);
    return this.user.role_id !== roleId;
  }

  /**
   * Performs the register
   * @param email email
   * @param password password
   */
  register(email: string, password: string) {
    return getFirebaseBackend()
      .registerUser(email, password)
      .then((response: any) => {
        const user = response;
        return user;
      });
  }

  /**
   * Reset password
   * @param email email
   */
  resetPassword(email: string) {
    return getFirebaseBackend()
      .forgetPassword(email)
      .then((response: any) => {
        const message = response.data;
        return message;
      });
  }

  /**
   * Logout the user
   */
  logout() {
    // logout the user
    // getFirebaseBackend().logout();
  }

  refreshToken() {
    return this.http.get(environment.baseUrl + "/auth/refreshtoken");
  }

  confirmInscription(
    user_id: number,
    code: string,
    role_id: string
  ): Observable<User> {
    return this.http.get<User>(
      environment.baseUrl +
        "/users/confirmInscription/" +
        user_id +
        "?verification_code=" +
        code +
        "&roleId=" +
        role_id
    );
  }
}
