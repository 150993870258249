import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Cohort } from "src/app/core/models/cohort";
import { Utils } from "../utils";

@Component({
  selector: "app-sprints",
  templateUrl: "./sprints.component.html",
  styleUrls: ["./sprints.component.scss"],
})
export class SprintsComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  timelineCarousel = Utils.initsilder();
  @Input()
  cohort: Cohort = new Cohort();
  @Input()
  move: boolean;
  @Output() moveNextSprint: EventEmitter<boolean> = new EventEmitter();

  constructor() {
    this.breadCrumbItems = [
      { label: "Sprints" },
      { label: "Sprints", active: true },
    ];
  }

  ngOnInit(): void {}

  clickToMove() {
    this.moveNextSprint.emit(true);
  }
}
