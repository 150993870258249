import { constants } from "src/app/core/helpers/constants";
import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
  {
      id: 1,
      label: 'Dashboard',
      link: "user/profil/dashboard",

  },
  {
    id: 2,
    label: "Projects",
    icon: "",
    link: "user/project/list",
  },
  {
    id: 8,
    label: "Announcements",
    icon: "",
    link: "announcement/list",
  },
  {
    id: 3,
    label: "Experts",
    icon: "",
    link: "user/experts/list",
  },
  {
    id: 4,
    label: "MyWallet",
    icon: "",
    link: "user/wallet/my-wallet",
  },
  {
    id: 5,
    label: "MyProjects",
    icon: "",
    link: "user/project/my-projects",
    roleId: constants.ROLE.PROPOSER.id,
  },
  {
    id: 6,
    label: "Session",
    icon: "",
    link: "user/experts/list-session",
    roleId: constants.ROLE.CHEXPERT.id || constants.ROLE.BEXPERT.id,
  },
  {
    id: 7,
    label: "My Session",
    icon: "",
    link: "user/experts/my-session",
    roleId: constants.ROLE.PROPOSER.id,
    hasProject: true,
  },
];
