import { OwlOptions } from "ngx-owl-carousel-o";
import { constants } from "src/app/core/helpers/constants";
import { combineLatest } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import Swal, { SweetAlertIcon } from "sweetalert2";
import moment from "moment";

export class Utils {
  public static getFristLettres(user) {
    return (
      user.first_name.charAt(0).toUpperCase() +
      user.last_name.charAt(0).toUpperCase()
    );
  }

  static getIdRole(key) {
    if (constants.ROLE.hasOwnProperty(key)) {
      return constants.ROLE[key].id;
    }
  }

  static initsilder() {
    var Options: OwlOptions;
    return (Options = {
      items: 1,
      loop: false,
      margin: 0,
      nav: true,
      navText: [
        "<i class='mdi mdi-chevron-left'></i>",
        "<i class='mdi mdi-chevron-right'></i>",
      ],
      dots: false,
      responsive: {
        680: {
          items: 4,
        },
      },
    });
  }
  static swalMessage(
    key: string,
    message: string,
    icon: SweetAlertIcon,
    transalteService: TranslateService
  ) {
    combineLatest(
      transalteService.get(key),
      transalteService.get(message)
    ).subscribe((res) => {
      Swal.fire(res[0], res[1], icon);
    });
  }

  static isEmail(email: string) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  }

  static swalMessageWithCallback(
    key: string,
    message: string,
    type: string,
    params: any,
    transalteService: TranslateService,
    callback
  ) {
    combineLatest(
      transalteService.get(key),
      transalteService.get(message)
    ).subscribe((res) => {
      let object = Object.assign(
        {
          title: res[0],
          text: res[1],
          icon: type,
        },
        params
      );
      Swal.fire(object).then(function (isConfirm) {
        callback(isConfirm);
      });
    });
  }

  static changeDropDownValue(className, index, themeClass) {
    const classSelector = "." + className + index + "";
    jQuery(classSelector).hasClass(themeClass)
      ? jQuery(classSelector).removeClass(themeClass) &&
        jQuery(classSelector).css("color", "")
      : jQuery(classSelector).addClass(themeClass) &&
        jQuery(classSelector).css("color", "#ffff");
  }

  static getNbVotesByRole(role_id) {
    if (constants.ROLE.PARTNER.id == role_id) {
      return 24;
    }
    if (
      constants.ROLE.BEXPERT.id == role_id ||
      constants.ROLE.CHEXPERT.id == role_id
    ) {
      return 12;
    }
    return 6;
  }

  static verifierRole(roleId) {
    if (roleId == constants.ROLE.PROPOSER.id) {
      return "proposer";
    }
    if (
      roleId == constants.ROLE.BEXPERT.id ||
      roleId == constants.ROLE.CHEXPERT.id
    ) {
      return "expert";
    }
    if (roleId == constants.ROLE.COMEMBER.id) {
      return "comember";
    }
    if (roleId == constants.ROLE.MENTOR.id) {
      return "mentor";
    }
    if (roleId == constants.ROLE.PARTNER.id) {
      return "partner";
    }
  }

  static verifierRoleInLogout(roleId) {
    if (roleId == constants.ROLE.PROPOSER.id) {
      return "proposer";
    }
    if (roleId == constants.ROLE.CHEXPERT.id) {
      return "chexpert";
    }
    if (roleId == constants.ROLE.BEXPERT.id) {
      return "bexpert";
    }
    if (roleId == constants.ROLE.COMEMBER.id) {
      return "comember";
    }
    if (roleId == constants.ROLE.MENTOR.id) {
      return "mentor";
    }
    if (roleId == constants.ROLE.PARTNER.id) {
      return "partner";
    }
  }

  static getStatus(status) {
    switch (status) {
      case 0: {
        return `Pending`;
      }
      case 1: {
        return `Completed`;
      }
    }
  }

  static getStatusSession(status) {
    switch (status) {
      case 0: {
        return `Pending`;
      }
      case 1: {
        return `Accepted`;
      }
      case -1: {
        return `Refused`;
      }
    }
  }
  static getStatusProject(status) {
    switch (status) {
      case 0: {
        return `Pending`;
      }
      case 1: {
        return `Completed`;
      }
      case -1: {
        return `Delay`;
      }
    }
  }

  static checkFonction(array, className, index) {
    for (let i = 0; i <= array.length; i++) {
      if (i != index) jQuery("#" + className + i).prop("checked", false);
      else jQuery("#" + className + index).prop("checked", true);
      if (index != null) {
        jQuery("#" + className + "all").prop("checked", false);
      }
    }
  }

  static isMobile(mobile) {
    return /^[0-9]+$/.test(mobile);
  }

  static isLink(link: string) {
    return /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&amp;//=]*)+$/.test(
      link
    );
  }

  static isString(name: string) {
    return /^[a-zA-Z]|[a-zA-Z] [a-zA-Z]+$/.test(name);
  }

  static getDifferenceInHours(date1, date2) {
    let firstDate = moment(date1);
    let secondDate = moment(date2);
    let diffInDays = firstDate.diff(secondDate, "hours");
    return diffInDays;
  }
}
