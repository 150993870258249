import { Activity } from "./activity";
import { Country } from "./country";
import { Experience } from "./experience";
import { Expertise } from "./expertise";
import { Project } from "./project";
import { Role } from "./role";

export class User {
  user_id: number;
  last_name: string;
  first_name: string;
  fullName: string;
  password: string;
  email: string;
  email_confirmed: string;
  verification_code: string;
  bio: string;
  phone: string;
  mobile: string;
  location: string;
  profil_img: string;
  status: number;
  role_id: number;
  birthdate: Date;
  occupation: string;
  public_address: string;
  role: Role = new Role();
  projects: Project[] = [];
  experiences: Experience[] = [];
  activites: Activity[] = [];
  country_id;
  country: Country = new Country();
  network_link: string;
  organization: string;
  blockchain_level: string;
  current_position: string;
  skills: string;
  session_price: number;
  session_duration: number;
  nb_tokens: number;
  github_link: string;
  website_link: string;
  expertises: Expertise[];
  user_expertise:any[]= [];
}
