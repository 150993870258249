import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { StorageService } from '../services/storage.service';
import { AuthenticationService } from '../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../models/user';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  count: number = 0;
  user: User;
  constructor(private storageService: StorageService, private authService: AuthenticationService, private router: Router, private route: ActivatedRoute) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.authService.getUserToken()}`
      }
    }
    );
    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && (error.status === 403 || error.status === 401)) {
        if (!this.authService.isLoggedIn()){
          return throwError(error);
        }
        if (this.count < 3){
          return this.handleError(request, next);
        }
        else {
          this.user = this.storageService.read(StorageService.USER_KEY);
          if (this.user.role_id == 1)
            this.router.navigateByUrl('/admin/login');
          else
            this.router.navigateByUrl('/user/proposer/login');
          setTimeout(() => {
            this.storageService.removeAll()
          }, 2500);
        }
      }
      return throwError(error);
    }));;
  }

  private handleError(request: HttpRequest<any>, next: HttpHandler) {
    return this.authService.refreshToken().pipe(
      switchMap((data: any) => {
        this.count = 0;
        this.storageService.write(StorageService.USER_TOKEN_KEY, data.token);
        return next.handle(request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.authService.getUserToken()}`
          }
        }));
      }),
      catchError((err) => {
        this.count++;
        return throwError(err);
      })
    );
  }
}
