<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/decentralize.png" alt="" height="50">
          </span>
          <span class="logo-lg">
            <img src="assets/images/decentralize.png" alt="" height="50">
          </span>
        </a>
      </div>


    </div>

    <div class="d-flex">

      <div class="dropdown d-inline-block d-lg-none me-2" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-search-dropdown" ngbDropdownToggle>
          <i class="mdi mdi-magnify"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu>

          <form class="p-3">
            <div class="m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="16"> <span
            class="ms-1">{{countryName}} <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </span>
          <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="16">
          <span *ngIf="flagvalue === undefined" class="ms-1">English</span>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
            (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
            <img src="{{item.flag}}" alt="user-image" class="ms-1" height="12"> <span
              class="align-middle"> {{item.text}}</span>
          </a>
          <!-- item-->
        </div>
      </div>




      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown"
          ngbDropdownToggle (click)="putAnnouncement()">
          <i class="bx bx-bell bx-tada"></i>
          <span class="badge bg-danger rounded-pill" *ngIf="!announcementCheck">{{announcements.length}}</span>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
          aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">{{ 'Announcements' | translate}} </h6>
              </div>
              <div class="col-auto">
              </div>
            </div>
          </div>
          <ngx-simplebar style="position: relative; height: 230px;">
            <a class="text-reset notification-item"  *ngFor="let announcement of announcements">
              <div class="media"  (click)="redirectToUrl(announcement.announcement_id)">
                <div class="avatar-xs me-3">
                  <span class="avatar-title bg-primary rounded-circle font-size-16">
                    <i class="bx bxs-megaphone
                    "></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ announcement.title}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>
                      {{announcement?.publishing_date | date:'d MMMM,yyyy
                      h:mm a'}}</p>
                  </div>
                </div>
              </div>
            </a>
          </ngx-simplebar>
        </div>
      </div>


      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <div>
            <span ngbTooltip="TeamMembers" title="{{user.first_name}}  {{user.last_name}}"
                class="header-profile-user rounded-circle bg-soft bg-light text-light font-size-32" style="padding-left: 8px;">
                {{getFristLettres(user)}}
            </span>
            <span class="d-none d-xl-inline-block ms-1">{{user.first_name}}  {{user.last_name}}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </div>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
              {{ 'Logout' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="topnav">
  <div class="container-fluid">
    <nav class="navbar navbar-light navbar-expand-lg topnav-menu active">
      <div class="collapse navbar-collapse active" id="topnav-menu-content">
        <ul class="navbar-nav">
          <ng-container *ngFor="let item of menuItems">
            <li class="nav-item dropdown"  *ngIf="hasPermission(item) && display(item) && hasWallet(item)">
              <a class="nav-link dropdown-toggle arrow-none" *ngIf="!item.subItems" href="javascript: void(0);"
                id="topnav-components" [routerLink]="item.link" role="button">
                <i class="bx {{item.icon}} me-2"></i>{{ item.label | translate }} <div class="arrow-down"
                  *ngIf="hasItems(item)"></div>
              </a>

              <a *ngIf="item.subItems" class="nav-link dropdown-toggle arrow-none" (click)="onMenuClick($event)"
                href="javascript: void(0);" id="topnav-components" role="button">
                <i class="bx {{item.icon}} me-1"></i>
                {{item.label| translate }}
                <div class="arrow-down"></div>
              </a>

              <div class="dropdown-menu" aria-labelledby="topnav-dashboard" *ngIf="hasItems(item)">
                <ng-template ngFor let-i="index" let-subitem [ngForOf]="item.subItems">
                  <a class="col dropdown-item side-nav-link-ref" *ngIf="!hasItems(subitem)" href="javascript: void(0);"
                    [routerLink]="subitem.link" routerLinkActive="active">{{subitem.label | translate }}</a>

                  <div class="dropdown" *ngIf="hasItems(subitem)">
                    <a class="dropdown-item dropdown-toggle" href="javascript: void(0);"
                      (click)="onMenuClick($event)">{{ subitem.label | translate }}
                      <div class="arrow-down"></div>
                    </a>
                    <div class="dropdown-menu">
                      <ng-template ngFor let-subSubitem [ngForOf]="subitem.subItems">
                        <a class="dropdown-item side-nav-link-ref" *ngIf="!hasItems(subSubitem)"
                          href="javascript: void(0);" [routerLink]="subSubitem.link"
                          routerLinkActive="active">{{ subSubitem.label | translate }}</a>
                        <div class="dropdown" *ngIf="hasItems(subSubitem)">

                          <a class="dropdown-item dropdown-toggle" href="javascript: void(0);"
                            (click)="onMenuClick($event)">{{ subSubitem.label | translate }}
                            <div class="arrow-down"></div>
                          </a>
                          <div class="dropdown-menu">
                            <ng-template ngFor let-subSubSubitem [ngForOf]="subSubitem.subItems">
                              <a class="dropdown-item side-nav-link-ref" href="javascript: void(0);"
                                [routerLink]="subSubSubitem.link"
                                routerLinkActive="active">{{ subSubSubitem.label | translate }}</a>
                            </ng-template>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </ng-template>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </nav>
  </div>
</div>
