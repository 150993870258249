export const environment = {
  production: true,
  defaultauth: "fackbackend",
  firebaseConfig: {
    apiKey: "",
    authDomain: "",
    databaseURL: "",
    projectId: "",
    storageBucket: "",
    messagingSenderId: "",
    appId: "",
    measurementId: "",
  },
  baseUrl: "https://dev.api.dao.vayetek.com/api",
  filesUrl: "https://dao-dev.s3.eu-west-3.amazonaws.com/",
  contratAddress: '0x94D025AEF31698FaE6d66317D5BfE991E82cb7F4',
  networkVersion:"250",
  chainName:"Fantom Opera",
  chainId: "0xFA",
  NativeCurrencyName:"FTM",
  symbol:"FTM",
  rpcUrls:"https://rpc.ftm.tools/",
  blockExplorerUrls:"https://ftmscan.com/",
  transactionUrl: "https://ftmscan.com/tx/",
  gaTrackingID: 'G-WE6RGV0KGT',
  contratIncubationAddress: "0xE6a77499A0530dad8c124F1419c7F371ec393AC8"
};

/*
  ropsten config

  contratAddress: "0xDd0D25e71Aec248B3D6Ad0d9d22D09E9cBef91f1",
  networkVersion: "3",
  chainName: "ETH",
  chainId: "0x3",
  NativeCurrencyName: "Ropsten Ether",
  symbol: "ETH",
  rpcUrls: "https://ropsten.infura.io/v3/",
  blockExplorerUrls: "https://ropsten.etherscan.io/",
  transactionUrl: "https://ropsten.etherscan.io/tx/",
*/

