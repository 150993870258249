import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "src/environments/environment";
import { User } from "../models/user";
import { catchError } from "rxjs/operators";
import { GenericService } from "./generic.service";
import { Observable } from "rxjs-compat";
import { Role } from "../models/role";
import { StorageService } from "./storage.service";

@Injectable({ providedIn: "root" })
export class UserProfileService extends GenericService {
  loggedUser: User;

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {
    super();
    this.loggedUser = <User>storageService.read(StorageService.USER_KEY);
  }

  getAll() {
    return this.http.get<User[]>(`/api/login`);
  }

  register(user: User, roleId) {
    const url = environment.baseUrl + `/users/register?roleId=` + roleId;
    return this.http.post(url, user).pipe(catchError(this.handleErrors));
  }

  getUserById(userId) {
    const url = environment.baseUrl + "/users/" + userId;
    return this.http.get(url);
  }
  getActivitesByIdUser(userId, page, perPage) {
    const url =
      environment.baseUrl +
      "/users/" +
      userId +
      "/activites?page=" +
      page +
      "&perPage=" +
      perPage;
    return this.http.get(url);
  }

  getRoles() {
    const url = environment.baseUrl + "/users/roles";
    return this.http.get(url);
  }

  getAllUsers(
    page: number,
    perPage: number,
    search: string,
    name: string,
    roleId: number,
    email,
    status: string,
    cohort_id: number,
    tri,
    order
  ) {
    const url =
      environment.baseUrl +
      `/users?page=${page}&perPage=${perPage}&search=${search}&name=${name}&role=${roleId}&email=${email}&status=${status}&cohort=${cohort_id}&tri=${tri}&order=${order}`;
    return this.http.get(url);
  }

  addUser(user: User) {
    const url = environment.baseUrl + "/users/add";
    return this.http.post<any>(url, user).pipe(catchError(this.handleErrors));
  }
  getProposer() {
    const url = environment.baseUrl + "/users/proposer/all";
    return this.http.get(url);
  }

  addUsersFromExcel(users: User[]) {
    const url = environment.baseUrl + "/users/add-users-excel";
    return this.http.post(url, {
      data: users,
    });
  }

  editRole(role: Role) {
    const url = environment.baseUrl + "/role/add";
    return this.http.post(url, role);
  }

  changeUserStatus(user_id: number, status: number) {
    const url = environment.baseUrl + "/users/" + user_id + "/changeStatus";
    return this.http.post(url, { status });
  }

  getAllExperts(page: number, perPage: number, name: string) {
    const url =
      environment.baseUrl +
      `/users/experts/all?page=${page}&perPage=${perPage}&name=${name}`;
    return this.http.get(url);
  }

  forgetPassword(roleId: number, email: string) {
    const url = environment.baseUrl + "/users/password/forgot/" + roleId;
    const body = { email: email };
    return this.http.post(url, body);
  }

  getUserByIdWithVerificationCode(user_id: number, verification_code: string) {
    const url =
      environment.baseUrl +
      "/users/confirm/" +
      user_id +
      "?verification_code=" +
      verification_code;
    return this.http.get(url);
  }

  resetPassword(user_id: number, verification_code: string, password: string) {
    const url = environment.baseUrl + "/users/password/reset/" + user_id;
    const body = { verification_code: verification_code, password: password };
    return this.http.post(url, body);
  }

  getLoggedUser() {
    const url = environment.baseUrl + "/users/get/me";
    return this.http.get(url);
  }

  getVoteHistory() {
    const url = environment.baseUrl + "/vote/user/history";
    return this.http.get(url);
  }

  changeUserStatusVote(user_id: number, can_vote: number) {
    const url =
      environment.baseUrl + "/users/" + user_id + "/vote/changeStatusVote";
    return this.http.post(url, { can_vote });
  }

  getUserProfil(user_id) {
    const url = environment.baseUrl + "/users/user/my-profil/" + user_id;
    return this.http.get(url);
  }

  editUser(user_id, user: User) {
    const url = environment.baseUrl + "/users/" + user_id + "/edit";
    return this.http.put<any>(url, user).pipe(catchError(this.handleErrors));
  }

  getNbClapPerExpert(user_id) {
    const url = environment.baseUrl + "/users/user/clap/" + user_id;
    return this.http.get(url);
  }
}
