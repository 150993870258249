import { Challenge } from "./challenge";
import { Partner } from "./partner";
import { Phase } from "./phase";

export class Cohort {
    cohort_id: number;
    name: string;
    start_date: Date;
    end_date: Date;
    description: string;
    active_phase_id: number;
    phases : Phase[]=[];
    challenges : Challenge[]=[];
    partners : Partner[]=[]
    active_phase : Phase;
}

