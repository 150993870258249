import { Component, OnInit, AfterViewInit, Inject } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { LanguageService } from "../../core/services/language.service";

import { EventService } from "../../core/services/event.service";
import { AuthenticationService } from "../../core/services/auth.service";
import { AuthfakeauthenticationService } from "../../core/services/authfake.service";

import { DOCUMENT } from "@angular/common";

import { MENU } from "./menu";
import { MenuItem } from "./menu.model";
import { environment } from "../../../environments/environment";
import { StorageService } from "src/app/core/services/storage.service";
import { User } from "src/app/core/models/user";
import { Utils } from "src/app/shared/ui/utils";
import { constants } from "src/app/core/helpers/constants";
import { AnnouncementService } from "src/app/core/services/announcement.service";
import { Announcement } from "src/app/core/models/announcement";
import { Subscription } from "rxjs";

@Component({
  selector: "app-horizontaltopbar",
  templateUrl: "./horizontaltopbar.component.html",
  styleUrls: ["./horizontaltopbar.component.scss"],
})

/**
 * Horizontal Topbar and navbar specified
 */
export class HorizontaltopbarComponent implements OnInit {
  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  announcements: Announcement[] = [];
  busy: Subscription;
  menuItems = [];
  user: User = new User();

  listLang = [
    { text: "English", flag: "assets/images/flags/us.jpg", lang: "en" },
    { text: "French", flag: "assets/images/flags/french.jpg", lang: "fr" },
  ];
  roleName: string;
  hasProject: boolean;
  announcementCheck: boolean;

  constructor(
    private router: Router,
    public languageService: LanguageService,
    private storageService: StorageService,
    public _cookiesService: CookieService,
    private announcementService: AnnouncementService
  ) {
    this.user = this.storageService.read(StorageService.USER_KEY);
    this.languageService.setLanguage("en");
    this.getCurrentAnnouncements();
    this.announcementCheck = this.storageService.read (StorageService.Announcements)
  }

  ngOnInit(): void {
    this.element = document.documentElement;

    this.initialize();

    this.cookieValue = this._cookiesService.get("lang");
    const val = this.listLang.filter((x) => x.lang === this.cookieValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = "assets/images/flags/us.jpg";
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
    this.roleName = Utils.verifierRoleInLogout(this.user.role_id);
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Logout the user
   */
  logout() {
    this.router.navigateByUrl("user/" + this.roleName + "/login");
    this.storageService.removeAll();
  }

  /**
   * On menu click
   */
  onMenuClick(event) {
    const nextEl = event.target.nextElementSibling;
    if (nextEl) {
      const parentEl = event.target.parentNode;
      if (parentEl) {
        parentEl.classList.remove("show");
      }
      nextEl.classList.toggle("show");
    }
    return false;
  }

  /**
   * Togglemenu bar
   */
  toggleMenubar() {
    const element = document.getElementById("topnav-menu-content");
    element.classList.toggle("show");
  }

  /**
   * Initialize
   */
  initialize(): void {
    this.menuItems = MENU;
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }

  hasPermission(item: MenuItem) {
    if (item.roleId !== undefined && item.roleId !== this.user.role_id)
      return false;
    else return true;
  }

  display(item: MenuItem) {
    this.hasProject = this.storageService.read(StorageService.Has_Project);
    if (item.hasProject !== undefined && item.hasProject !== this.hasProject)
      return false;
    else return true;
  }

  getFristLettres(user) {
    return Utils.getFristLettres(user);
  }

  hasWallet(item: MenuItem) {
    if (item.id == 4) {
      if (this.user.role_id != constants.ROLE.MENTOR.id) return true;
      else return false;
    }
    return true;
  }

  getCurrentAnnouncements() {
    this.busy = this.announcementService
      .getCurrentAnnouncements()
      .subscribe((data: Announcement[]) => {
        this.announcements = data;
      });
  }

  redirectToUrl(annoucement_id) {
    this.router
      .navigate(["/announcement/landing-page/" + annoucement_id])
      .then(() => {
        window.location.reload();
      });
  }

  putAnnouncement() {
    this.announcementCheck = true
    this.storageService.write(StorageService.Announcements, true);
  }
}
